<template>
    <div class="row">
        <div class="col-md-12">
            <h3 class="text-muted mt-5">Fetch locations</h3>
            <p>To fetch a list of all the associated locations; is executed by issuing a <code>POST</code> to the following URI <code>{{ url }}</code>, this will return a list for all the locations as their associated data</p>

            <p>A successful response also includes a list of accesses from said location, each access has the properties of <strong> name, id and color. </strong> </p>            
            <img src="@/assets/imagenes/diagrama.png" alt="Diagrama entidad relación" id="diagrama">

            <h5 class="text-muted">Ejemplo</h5>
            <div class="row mb-3">
                <div class="col-md-4">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon3">token</span>
                        </div>
                        <input type="text" class="form-control" aria-describedby="basic-addon3" v-model="token">
                    </div>
                    <loading-button @click.native="peticion" class="btn btn-primary" :loading="loading">Execute request</loading-button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h5 class="text-muted">Petición</h5>
                    <div class="terminal">
                        {{ curlPlazas }}
                    </div>
                </div>
                <div class="col-md-6">
                    <h5 class="text-muted">Response</h5>
                    <json-viewer
                        :value="response"
                        boxed
                    ></json-viewer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer'
import axios from 'axios'
import ButtonLoading from 'vue-loading-button'

export default {
    name: 'plazas',
    components: {
        'json-viewer': JsonViewer,
        'loading-button': ButtonLoading
    },
    data(){
        return {
            url: 'https://app.pogendata.com/api/data/plazas',
            token: '',
            loading: false,
            response: 'Execute the request to get a response'
        }
    },
    computed: {
        curlPlazas() {
            let token = ( this.token ) ? this.token : '{token}';
            let curl = "curl -XPOST -H 'Authorization: bearer "+token+"' '"+this.url+"'";
            return curl;
        }
    },
    methods: {
        peticion(){
            if( !this.token ){
                this.response = 'A token is required to execute the request';
                return false;
            }
            this.loading = true
            axios.post(this.url, {}, {
                headers: {
                    authorization: 'bearer '+this.token
                }
            }).then(response => {
                this.response = response.data
                this.loading = false
            }).catch(response => {
                this.response = response
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>
  .terminal{
    background-color: #000;
    padding: 10px;
    color: yellowgreen;
    overflow: auto;
  }
  #diagrama{
      width: 40vw;
  }
</style>