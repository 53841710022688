<template>
    <div class="row mt-5">
        <div class="col-md-12">
            <h3 class="text-muted">Obtener datos</h3>
            <p>This API returns the data for the locations within the provided data range with a <kbd>limit of 1,000</kbd> entries per request, it's executed issuing a <code>POST</code> to the URI <code>{{ url }}</code>.</p>
            <p>Parameters</p>
            <table class="table table-hover table-striped">
                <thead>
                    <th>Parameter</th>
                    <th>Type</th>
                    <th>Description</th>
                </thead>
                <tbody>
                    <tr>
                        <td>plaza_id</td>
                        <td>INT</td>
                        <td>If provided, returns the data for just that location, if not provided it will return the data for all the locations associated to the user</td>
                    </tr>
                    <tr>
                        <td>inicio</td>
                        <td>TEXT</td>
                        <td>If provided, returns data starting from the specified date. Date format must be: YYYY-MM-DD HH:mm:SS</td>
                    </tr>
                    <tr>
                        <td>fin</td>
                        <td>TEXT</td>
                        <td>If provided, returns date ending on the specified date. Date format must be: YYYY-MM-DD HH:mm:SS</td>
                    </tr>
                    <tr>
                        <td>tipo</td>
                        <td>TEXT</td>
                        <td>It's mandataory and established how the return data is organized, by hours, days, weeks, months or years.<br>Allowed values are [hours, days, weeks, months, years]</td>
                    </tr>
                    <tr>
                        <td>pagina</td>
                        <td>INT</td>
                        <td>Establishes the page numbre for the return data, if ommited it defaults to 0, that is the first page of the result set</td>
                    </tr>
                    <tr>
                        <td>agrupar</td>
                        <td>BOOL</td>
                        <td>If provided, groups the return data by location<br>Allowed values are [0,1]</td>
                    </tr>
                    <tr>
                        <td>campo_orden</td>
                        <td>TEXT</td>
                        <td>Used to establish the data field to be use to sort the return data, one of the follwing values is expected<code>fecha</code> to sort by date or <code>insercion</code> to sort by date<br>
                            Default value: <i>fecha</i>
                        </td>
                    </tr>
                    <tr>
                        <td>orden</td>
                        <td>TEXT</td>
                        <td>Used to establish the sorting order, ascending or descending, for the return data one of the following value is expected <code>asc</code> or <code>desc</code><br>
                            Default value: <i>asc</i>
                        </td>
                    </tr>
                    <tr>
                        <td>salidas</td>
                        <td>INT</td>
                        <td>
                            Devuelve las salidas registradas según los parámetros establecidos.
                            <p>
                                <strong>
                                    * Solo si las salidas están habilitadas para el establecimiento en cuestión
                                </strong>
                            </p>
                        </td>                    
                    </tr>                    
                </tbody>
            </table>

            <div class="row mt-5">
                <div class="col-md-12">
                    <h5 class="text-muted">Ejemplo</h5>
                    
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">token</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" v-model="token">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">plaza_id</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" v-model="peticion.plaza_id">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">inicio</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" :placeholder="[[ fecha ]]" v-model="peticion.inicio">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">fin</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" :placeholder="[[ fecha ]]" v-model="peticion.fin">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">tipo</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" placeholder="days" v-model="peticion.tipo">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">pagina</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" placeholder="1" v-model="peticion.pagina">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">agrupar</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" placeholder="1" v-model="peticion.agrupar">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">campo_orden</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" placeholder="fecha" v-model="peticion.campo_orden">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">orden</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" placeholder="asc" v-model="peticion.orden">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">salidas</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" placeholder="0" v-model="peticion.salidas">
                            </div>
                            <loading-button @click.native="request" class="btn btn-primary" :loading="loading" :styled="style">Hacer petición</loading-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <h5 class="text-muted">Petición</h5>
                            <div class="terminal">
                                {{ curl }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <h5 class="text-muted">Respuesta</h5>
                            <json-viewer
                                :value="response"
                                boxed
                            ></json-viewer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import JsonViewer from 'vue-json-viewer'
import _ from 'lodash'
import axios from 'axios'
import VueLoadingButton from 'vue-loading-button'

export default {
    name: 'datos',
    components: {
        'json-viewer': JsonViewer,
        'loading-button': VueLoadingButton
    },
    data(){
        return {
            url: 'https://app.pogendata.com/api/data/datos',
            fecha: moment().format('YYYY-MM-DD HH:mm:ss'),
            loading: false,
            style: false,
            token: '',
            peticion: {
                plaza_id: '',
                inicio: '',
                fin: '',
                tipo: '',
                pagina: '',
                agrupar: '',
                campo_orden: '',
                orden: '',
                salidas:''
            },
            response: 'Realiza la petición para ver una respuesta'
        }
    },
    computed: {
        curl() {
            let token = ( this.token ) ? this.token : '{token}';
            let request = []
            _.each(this.peticion, function(value, key){
                if( value ){
                    request.push(key+'='+value)
                }
            })
            let data = ''
            if( request.length ){
                data = '-d '
                data += request.join('&')
                data += ' '
            }
            let curl = "curl -XPOST "+data+"-H 'Authorization: bearer "+token+"' '"+this.url+"'";
            return curl;
        }
    },
    methods:{
        request(){
            if( !this.token ){
                this.response = 'A token is required to execute request'
                return false
            }
            this.loading = true
            let request = {}
            _.each(this.peticion, function(value, key){
                if( value ){
                    request[key] = value
                }
            })

            axios.post(this.url, request, {
                headers: {
                    authorization: 'bearer '+this.token
                }
            }).then(response => {
                this.response = response.data
                this.loading = false
            }).catch(error => {
                this.response = error.response
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>
  .terminal{
    background-color: #000;
    padding: 10px;
    color: yellowgreen;
    overflow: auto;
  }
</style>