<template>
    <div class="row">
        <div class="col-md-12">
            <h3 class="text-muted mt-5">Start a session</h3>
            <p>Use to fecth the token needed for further requests; is executed by issuing a <code>POST</code> the the following URI <code>{{ url }}</code> using the same credentials to access POGEN's portal.</p>
            <p>Parameters:</p>
            <ul>
                <li>email</li>
                <li>password</li>
            </ul>
            <p>The return token is valid up to one hour of inactivity.</p>
            
            <h5 class="text-muted">Ejemplo</h5>
            <div class="row">
                <div class="col-md-4">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon3">email</span>
                        </div>
                        <input type="text" class="form-control" aria-describedby="basic-addon3" placeholder="nombre@ejemplo.com" v-model="email">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon3">password</span>
                        </div>
                        <input type="text" class="form-control" aria-describedby="basic-addon3" placeholder="contraseña" v-model="password">
                    </div>
                    <loading-button @click.native="execLogin" class="btn btn-primary" :loading="loading" :styled="style">Execute request</loading-button>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-6">
                    <h5 class="text-muted">Petición</h5>
                    <div class="terminal">
                        {{ curlLogin }}
                    </div>
                </div>
                <div class="col-md-6">
                    <h5 class="text-muted">Response</h5>
                    <json-viewer :value="response"></json-viewer>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios'
import JsonViewer from 'vue-json-viewer'
import LoadingButton from 'vue-loading-button'
export default {
  name: 'documentacion',
  components: {
    'json-viewer': JsonViewer,
    'loading-button': LoadingButton
  },
  data() {
    return {
        url: 'https://app.pogendata.com/api/login',
        loading: false,
        email: '',
        password: '',
        response: 'Execute request to get a response'
    }
  },
  computed: {
    curlLogin: function(){
      let email = (this.email) ? this.email : '{email}';
      let password = (this.password) ? this.password : '{password}';
      return "curl -XPOST -d 'email="+email+"&password="+password+"' '"+this.url+"'";
    }
  },
  methods: {
    execLogin(){
      let post = {
        email: this.email,
        password: this.password
      };
      if(!post.email){
        this.response = 'email is a mandatory value';
        return false;
      }
      if(!post.password){
        this.response = 'password is a mandatory value';
        return false;
      }
      this.loading = true
      axios.post(this.url, post).then(response => {
          this.response = response.data
          this.loading = false
        }).catch(response => {
          this.response = response
          this.loading = false
        });
    }
  }
}
</script>

<style scoped>
  .terminal{
    background-color: #000;
    padding: 10px;
    color: yellowgreen;
    overflow: auto;
  }
</style>