<!-- Código html -->
<template>
    
  <div class="container">
    <h2>Pogen Data API Documentation</h2>
    <h3 class="text-muted mt-5">Introduction</h3>
    <p>The following APIs allow the integration of POGEN's data with customer's application and platforms Enables progamatic access to list locations, and access locations entries by location and/or date and time. The return data is provided in <code>JSON</code> format</p>
    <p>Requests' execution might take up to <strong>50 seconds</strong> based on the type of request and amount of data to be returned, consider this in order to adjust your system's and application's timeouts accordingly</p>


    <login-component></login-component>

    <h3 class="text-muted mt-5">Requests that require an access token</h3>
    <p>Each request that returns users's information requires a token, once obtained, the token must be included in the request in the headers using <code>Authorization bearer {token}</code>.</p>
    
    <plazas-component></plazas-component>
    <datos-component></datos-component>
    
    <br>
  </div>

</template>

<!-- Código Vue -->
<script>
  import LoginComponent from '../components/data/Login_en.vue'
  import PlazasComponent from '../components/data/Plazas_en.vue'
  import DatosComponent from '../components/data/Datos_en.vue'
  export default {
    name: 'documentacion',
    components: {
      'login-component': LoginComponent,
      'plazas-component': PlazasComponent,
      'datos-component': DatosComponent
    }
  }
</script>

<!-- Estilo propio -->